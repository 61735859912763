import React from "react";
import Link from "gatsby-plugin-transition-link";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Button = ({
  className,
  href,
  outboundLink,
  modal,
  onClick,
  type,
  altStyle,
  icon,
  text,
}) => {
  const Tag = href && href.includes("#") ? AnchorLink : href ? Link : "button";
  const target = outboundLink && "_blank";
  const rel = outboundLink && "noopener noreferrer";
  let link = true;
  let anchor = false;

  href && (link = !href.includes("tel:") && !href.includes("mailto:"));

  return (
    <Tag
      className={`group inline-flex items-center justify-center space-x-2.5 font-heading font-bold no-underline
       ${
         altStyle === 2
           ? "text-secondary-500 hover:text-secondary-500"
           : altStyle === 3
           ? ""
           : "text-primary-500 hover:text-primary-500"
       } ${className}`}
      {...(anchor ? { to: href } : link ? { to: href } : { href: href })}
      target={target}
      rel={rel}
      data-modal-open={modal}
      onClick={onClick}
      type={type}
      alt-style={altStyle}
    >
      <div className="relative flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-r from-secondary-500 to-primary-500">
        {altStyle === 2 ? (
          <div className="absolute inset-0 m-px rounded-full bg-white opacity-100 transition-opacity duration-300 ease-linear group-hover:opacity-0" />
        ) : (
          <div className="absolute inset-0 m-px rounded-full bg-[#1D1D1D] opacity-100 transition-opacity duration-300 ease-linear group-hover:opacity-0" />
        )}

        <i
          className={`z-10 text-lg transition-all duration-300 ease-linear ${
            icon || "fa-light fa-plus-large"
          }  ${
            altStyle === 2
              ? `text-secondary-500 group-hover:text-white`
              : altStyle === 3
              ? ``
              : `text-primary-500 group-hover:text-white`
          }`}
        ></i>
      </div>

      <div>{text}</div>
    </Tag>
  );
};

export default Button;
