import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { mapEdgesToNodes } from "../../lib/helpers";
import SliderProgressBar from "../Slider/SliderProgressBar";

const StyledSlider = styled.div`
  .slick-dots {
    ${tw`relative mt-7 md:mt-0 justify-center items-center space-x-3 !flex`}
    li {
      ${tw`h-1 bg-primary-100/30 hover:bg-primary-500 mr-0 flex-1 transition-colors duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-100`}
      }
    }
  }
`;

const Testimonial = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "0.0 Repeating Modules/Yelp-alt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 74)
        }
      }
      google: file(
        relativePath: { eq: "0.0 Repeating Modules/Google icon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 61)
        }
      }
      googleWhite: file(
        relativePath: { eq: "0.0 Repeating Modules/Google white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 61)
        }
      }
      facebook: file(
        relativePath: { eq: "0.0 Repeating Modules/Facebook.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 123)
        }
      }
      testimonials: allSanityTestimonials(
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            id
            name
            review
            featured
            platform
            categories {
              title
            }
          }
        }
      }
    }
  `);

  let testimonialsNodes = (data || {}).testimonials
    ? mapEdgesToNodes(data.testimonials).filter((items) => items.featured)
    : [];

  return (
    <section className={`mb-20 md:mb-32 overflow-x-hidden ${className}`}>
      <div className="container">
        <header className="mb-14 text-center md:mb-14">
          <h2>Real Clients. Honest Feedback.</h2>
        </header>

        <StyledSlider className="relative">
          <SliderProgressBar slideCount={testimonialsNodes.length}>
            {testimonialsNodes.map((testimonial, i) => {
              return (
                <div
                  key={i}
                  className={`h-full rounded-tr-6xl rounded-bl-6xl px-10 py-10 pb-12 ${
                    testimonial.categories.length
                      ? testimonial.categories.slice(0, 1).map((category) => {
                          return category.title === "Business"
                            ? "bg-primary-100"
                            : category.title === "Personal"
                            ? "bg-secondary-100"
                            : null;
                        })
                      : "bg-gray-800"
                  }`}
                >
                  <blockquote className="">
                    <div className="mb-10">
                      {testimonial.platform === "Google" &&
                      testimonial.categories.length ? (
                        testimonial.categories.slice(0, 1).map((category) => {
                          return category.title === "Business" ? (
                            <GatsbyImage
                              image={
                                data.google.childImageSharp.gatsbyImageData
                              }
                            />
                          ) : category.title === "Personal" ? (
                            <GatsbyImage
                              image={
                                data.google.childImageSharp.gatsbyImageData
                              }
                            />
                          ) : null;
                        })
                      ) : (
                        <GatsbyImage
                          image={
                            data.googleWhite.childImageSharp.gatsbyImageData
                          }
                        />
                      )}

                      {testimonial.platform === "Yelp" && (
                        <GatsbyImage
                          image={data.yelp.childImageSharp.gatsbyImageData}
                        />
                      )}

                      {testimonial.platform === "Facebook" && (
                        <GatsbyImage
                          image={data.facebook.childImageSharp.gatsbyImageData}
                        />
                      )}
                    </div>

                    <q
                      className={`mb-9 text-xl font-semibold before:hidden ${
                        testimonial.categories.length &&
                        testimonial.categories.slice(0, 1).map((category) => {
                          return category.title === "Business"
                            ? "text-gray-500"
                            : category.title === "Personal"
                            ? "text-gray-500"
                            : null;
                        })
                      }`}
                    >
                      {testimonial.review}
                    </q>

                    <footer>
                      <div>
                        <cite className="not-italic">
                          <div
                            className={`text-sm font-light uppercase tracking-wide ${
                              testimonial.categories.length &&
                              testimonial.categories
                                .slice(0, 1)
                                .map((category) => {
                                  return category.title === "Business"
                                    ? "text-gray-900"
                                    : category.title === "Personal"
                                    ? "text-gray-900"
                                    : null;
                                })
                            }`}
                          >
                            {testimonial.name}
                          </div>
                        </cite>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              );
            })}
          </SliderProgressBar>
        </StyledSlider>
      </div>
    </section>
  );
};

export default Testimonial;
