import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonIcon from "../Button/ButtonIcon";

const About = ({ headingLevel, paddingTop, location, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={` ${className || "mb-20 md:mb-32"} ${
        paddingTop && "pt-20 md:pt-32"
      }`}
    >
      <div className="container">
        <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div className="order-2 md:order-1">
            <HeadingTag>Who We Are</HeadingTag>
            <p>
            DeBlanc, Murphy & Murphy is a full-service{" "}
              {location ? location + " CPA" : "accounting"} firm capable of
              providing clients with a wide range of financial services,
              including:
            </p>
            <ul className="styled-list-checkmark mb-6 grid gap-x-4 space-y-0 md:grid-cols-2">
              <div>
                <li>Tax planning and preparation</li>
                <li>
                  Private company and government audit & attestation services
                </li>
                <li>Accounting and bookkeeping</li>
                <li>Business consulting</li>
                <li>Personal financial planning</li>
                <li>Wealth management</li>
                <li>IRS representation</li>
              </div>
              <div>
                <li>Litigation support</li>
                {/* <li>Business valuations</li> */}
                <li>Fraud prevention & detection</li>
                <li>Expert witness services</li>
                <li>Cryptocurrency consulting</li>
                <li>Family office services</li>
              </div>
            </ul>
            <p>
              The firm was founded in 1978 with one goal in mind—to create value
              for clients so they can achieve their goals and objective. We’ve
              kept that same intention for decades and serve a wide range of
              clients (big and small) in diverse industries.
            </p>
            <ButtonIcon href="/about/" text="Learn More" />
          </div>

          <div className="order-1 md:order-2">
            <StaticImage
              src="../../images/0.0 Repeating Modules/About us.png"
              loading="lazy"
              width={498}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
