import React, { useRef, useState } from "react";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-track {
    ${tw`ml-0 flex!`}
  }
  .slick-slide {
    ${tw`h-auto!`}
  }
  .slick-slide > div {
    ${tw`mx-2.5 md:mx-4 h-full`}
  }
  .slick-list {
    ${tw`-mx-2.5 md:-mx-4 p-0! overflow-visible`}
  }
`;

const Slider = ({ children, slideCount }) => {
  const slider = useRef();

  const [progressWidth, setProgressWidth] = useState(0);

  const sliderSettings = {
    dots: false,
    arrows: false,
    speed: 750,
    autoplay: false,
    autoplaySpeed: 7500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    afterChange: (next) => setProgressWidth((next / (slideCount - 1)) * 100),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <StyledSlider className="relative">
      <Slick {...sliderSettings} ref={slider}>
        {children}
      </Slick>

      <div
        className="progress relative mx-auto mt-10 h-2 w-full max-w-[500px] rounded-[20px] bg-[#C4C4C4]/40 md:mt-14"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          className="absolute left-0 top-0 h-2 rounded-[20px] bg-[#C4C4C4]/80 transition-all duration-300 ease-linear"
          style={{ width: progressWidth + "%" }}
        />
      </div>
    </StyledSlider>
  );
};

export default Slider;
